"use client";

import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cva, VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import Icon from "./Icon";
import { CalculatorModeString } from "./types";
import { cn } from "@/lib/utils";

const toggleVariants = cva(
  "inline-flex items-center flex-grow justify-center rounded-full text-sm font-normal " +
    "ring-offset-background transition-colors hover:text-[#E0E0E0] text-[#6C6C6C] " +
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 " +
    "disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-frGrey-700 " +
    "data-[state=on]:text-[#E0E0E0]",
  {
    variants: {
      variant: {
        default: "bg-frGray-800",
        outline:
          "border border-input bg-transparent hover:bg-accent hover:text-[#E0E0E0]",
      },
      size: {
        default: "h-6.25 px-3",
        sm: "h-6.25 px-3",
        lg: "h-11 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export const ModeToggleGroupItem = forwardRef<
  React.ElementRef<typeof ToggleGroupItem>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupItem> &
    VariantProps<typeof toggleVariants>
>(({ children, ...rest }, ref) => {
  return (
    <ToggleGroupItem {...rest} toggleVariantsFn={toggleVariants}>
      {children}
    </ToggleGroupItem>
  );
});

ModeToggleGroupItem.displayName = "ModeToggleGroupItem";

export interface CalculatorModeSelectorProps {
  calculatorMode: CalculatorModeString;
  setCalculatorMode: (value: CalculatorModeString) => void;

  background?: boolean;

  itemClassName?: string;
}

export const CalculatorModeSelector = ({
  calculatorMode,
  setCalculatorMode,
  background = true,
  itemClassName,
}: CalculatorModeSelectorProps) => {
  return (
    <div
      className={cn("p-4 border-frGrey-800 md:px-0", {
        "bg-frGrey-900 border-b md:bg-frGrey-1200": background,
      })}
    >
      <ToggleGroup
        type="single"
        className="bg-frGrey-800 rounded-full"
        value={calculatorMode}
        onValueChange={(newValue: string | null) => {
          if (newValue) {
            setCalculatorMode(newValue as CalculatorModeString);
          }
        }}
        tabIndex={1}
      >
        <ModeToggleGroupItem
          value={CalculatorModeString.Simple}
          aria-label="Simple"
          className={itemClassName}
        >
          <Icon icon="TopologyRing2" className="h-4 w-4 mr-1" />
          Simple
        </ModeToggleGroupItem>
        <ModeToggleGroupItem
          value={CalculatorModeString.Advanced}
          aria-label="Advanced"
          className={itemClassName}
        >
          <Icon icon="MathSymbols" className="h-4 w-4 mr-1" />
          Advanced
        </ModeToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};
